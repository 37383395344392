import { CSSProperties } from 'react';

type MathemLogoProps = {
    style?: CSSProperties;
};

export function MathemLogo({ style }: MathemLogoProps) {
    return (
        <svg
            fill="none"
            height="330"
            style={style}
            viewBox="0 0 1321 330"
            width="1321"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_500_29)">
                <path
                    d="M1253.28 221.009C1253.28 221.009 1242.21 224.367 1238.87 224.367C1232.25 224.367 1223.47 222.305 1223.41 204.511V155.22C1223.41 120.068 1202.16 96.4618 1170.52 96.4618C1150.74 96.4618 1133.95 104.32 1123.8 119.865C1114.73 105.117 1099.9 96.4618 1083.19 96.4618C1067.23 96.4618 1052.8 102.242 1043.48 113.694V100.336H1006.57V257.129H1044.11V171.046C1044.11 159.969 1046.89 150.721 1052.14 144.299C1057.01 138.363 1063.64 135.222 1071.32 135.222C1085.44 135.222 1095.56 146.205 1095.56 164.594V257.129H1133.92V171.046C1133.92 149.283 1146.27 135.222 1162.81 135.222C1176.74 135.222 1185.06 146.205 1185.06 164.594L1185.05 205.964C1185.05 223.18 1189.85 237.272 1198.95 246.724C1207.45 255.551 1219.44 260.222 1233.62 260.222C1252.53 260.222 1265.96 251.848 1265.96 251.848L1253.28 221.009Z"
                    fill="white"
                />
                <path
                    d="M649.42 218.915C649.42 218.915 640.231 224.805 630.562 224.805C619.442 224.805 613.575 218.009 613.575 205.167V136.035H651.007V99.9457H613.575V61.4974H575.724V99.9457H554.531V136.035H575.724V206.76C575.724 223.227 580.798 236.96 590.391 246.474C599.431 255.442 611.854 260.191 626.311 260.191C647.429 260.191 662.217 250.067 662.217 250.067L649.42 218.915Z"
                    fill="white"
                />
                <path
                    d="M552.72 217.775C552.72 217.775 548.26 223.539 540.208 223.539C536.466 223.539 532.65 222.305 532.65 213.119V99.9457H495.413V112.132C484.846 101.508 470.463 96.1337 452.548 96.1337C432.373 96.1337 414.563 103.367 399.626 117.631C384.614 132.301 377.011 152.767 377.011 178.483C377.011 189.622 379.137 203.276 381.905 210.869C369.543 228.804 347.467 232.695 344.803 206.292C343.68 193.262 343.216 188.169 342.199 175.686C338.667 132.254 331.887 41.25 331.887 41.25H295.922L234.917 171.374L182.31 41.25H142.259C142.259 41.25 122.413 219.774 120.317 231.226C117.728 245.459 110.784 252.083 98.496 252.083C94.5598 252.083 82.9306 248.193 82.9306 248.193L68.8021 280.126C68.8021 280.126 82.4517 288.75 101.908 288.75C132.979 288.75 153.409 268.768 157.959 233.913L171.997 110.929L219.427 224.477H249.914L300.142 116.178L307.237 204.901C309.362 242.553 325.286 260.082 357.375 260.082C377.595 260.082 390.257 253.364 400.569 240.287C411.046 251.63 431.49 260.16 449.196 260.175C469.266 260.175 486.642 252.848 500.951 238.397C506.728 251.77 519.24 259.863 534.715 259.863C554.935 259.863 564.2 245.787 564.2 245.787L552.72 217.775ZM424.56 213.697C416.419 205.198 412.213 193.246 412.213 177.842C412.213 146.705 431.49 129.395 454.404 129.395C466.153 129.395 476.106 133.488 484.846 141.972C493.288 150.455 497.508 162.094 497.508 177.514C499.289 224.071 447.759 239.803 424.56 213.697Z"
                    fill="white"
                />
                <path
                    d="M966.999 117.084C954.082 103.601 937.469 96.7586 917.638 96.7586C896.85 96.7586 878.74 103.992 863.803 118.256C848.597 132.817 840.889 152.955 840.889 178.155C840.889 190.122 842.685 201.23 846.083 210.369C838.09 225.649 824.815 226.867 819.158 224.571C812.363 221.993 809.863 215.462 809.863 205.776V151.549L809.743 151.595C808.905 135.113 803.502 121.88 793.669 112.241C783.178 101.961 770.082 96.743 754.711 96.743C738.188 96.743 725.78 102.258 715.798 115.366V42.0936H677.947V257.801H715.798V176.545C715.798 150.127 727.292 134.988 745.881 134.988C762.718 134.988 771.997 146.596 771.997 167.656V207.338C771.997 254.223 806.705 259.66 812.887 260.394C823.947 262.05 844.481 262.941 865.509 239.678C872.11 246.13 890.264 260.8 919.18 260.8C943.426 260.8 963.855 251.317 979.99 232.632L952.96 210.244C944.489 219.04 933.458 223.508 920.093 223.508C901.28 223.508 887.465 213.666 882.422 196.933H984.749C984.749 196.933 986.5 190.778 986.5 174.952C986.47 149.689 980.094 130.754 966.999 117.084ZM879.114 166.39C881.239 131.129 920.991 119.365 941.076 139.066C948.081 145.424 951.418 154.626 951.732 166.39H879.114Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_500_29">
                    <rect width="1321" height="330" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
