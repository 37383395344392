import { CSSProperties } from 'react';

import { MathemLogo } from './Mathem';

type LogoProps = {
    style?: CSSProperties;
};

function Logo({ style }: LogoProps) {
    return (
        <svg
            fill="none"
            height="80"
            style={style}
            viewBox="0 0 200 80"
            width="200"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M128.236 0.276291H117.63C116.736 0.276291 116.001 1.01119 116.001 1.90497V21.7471C116.001 22.8196 114.75 23.3956 113.936 22.7004C109.546 18.9664 104.601 17.5761 98.4831 17.5761C94.5107 17.5761 90.6575 18.5096 87.1221 20.0787C75.6618 25.1832 68.9881 36.147 68.9881 48.5408C68.9881 65.0064 81.6005 79.2872 98.4831 79.2872C104.481 79.2872 110.937 77.2612 115.108 72.9115C115.743 72.256 116.776 72.5341 117.054 73.2889V75.8312C117.054 76.7051 117.749 77.4003 118.623 77.4003H128.276C129.15 77.4003 129.845 76.7051 129.845 75.8312V1.90497C129.865 1.01119 129.13 0.276291 128.236 0.276291ZM99.5358 65.3242C89.6446 65.3242 80.3094 58.1342 80.3094 48.3224C80.3094 38.5106 89.8631 31.539 99.655 31.539C109.665 31.539 118.564 38.5304 118.564 48.3224C118.544 58.3328 109.765 65.3242 99.5358 65.3242ZM31.2107 17.5761C14.1095 17.5761 0.345215 31.539 0.345215 48.5408C0.345215 65.4235 14.328 79.2872 31.2107 79.2872C48.7289 79.2872 61.8577 65.8406 61.8577 48.3224C61.8577 31.3404 48.3118 17.5761 31.2107 17.5761ZM31.2107 65.3242C21.3194 65.3242 11.9843 58.1342 11.9843 48.3422C11.9843 38.5503 21.5578 31.5589 31.3299 31.5589C41.3403 31.5589 50.2384 38.5503 50.2384 48.3422C50.2186 58.3527 41.4396 65.3242 31.2107 65.3242ZM197.396 19.6616H187.743C186.869 19.6616 186.174 20.3567 186.174 21.2307V23.773C185.896 24.5476 184.863 24.8257 184.227 24.1504C180.056 19.8006 173.601 17.7747 167.603 17.7747C150.74 17.7747 138.108 32.0554 138.108 48.521C138.108 64.9866 150.72 79.2673 167.603 79.2673C173.601 79.2673 180.056 77.2414 184.227 72.8916C184.863 72.2362 185.896 72.5142 186.174 73.269V75.8113C186.174 76.6852 186.869 77.3804 187.743 77.3804H197.396C198.27 77.3804 198.965 76.6852 198.965 75.8113V21.2307C198.965 20.3567 198.27 19.6616 197.396 19.6616ZM168.636 65.3242C158.744 65.3242 149.409 58.1342 149.409 48.3224C149.409 38.5106 158.963 31.539 168.755 31.539C178.765 31.539 187.663 38.5304 187.663 48.3224C187.663 58.3328 178.864 65.3242 168.636 65.3242Z"
                fill="#F5F5F5"
            />
        </svg>
    );
}

export { MathemLogo };
export default Logo;
