import './styles.scss';

import { Stack } from '@kolonialno/lama';
import { PropsWithChildren } from 'react';

import Logo, { MathemLogo } from '../Logo';

type ContainerProps = {
    title: string;
};

function Container({ title, children }: PropsWithChildren<ContainerProps>) {
    return (
        <div className="authentication">
            <Stack
                className="container"
                justifyContent="center"
                alignItems="center"
                direction="vertical"
            >
                <Stack alignItems="center" className="inner-container">
                    <Stack
                        direction="vertical"
                        spacing="xs"
                        alignItems="center"
                    >
                        <Logo style={{ height: 40, width: 'auto' }} />
                        <hr className="logo-divider" />
                        <MathemLogo style={{ height: 40, width: 'auto' }} />
                    </Stack>

                    {children}
                </Stack>
            </Stack>
        </div>
    );
}

export default Container;
